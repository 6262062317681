import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from "../components/Layout"
import logo from "../assets/images/base_logo.png"

class YogaPosesPageIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    

    return (
      <Layout location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <div className='general_header basepage_header'style={{color:'#ffffff'}} />          
          <div className="page_wrapper"  style={{minHeight:'50vmin'}}>
            <h1 className="section-headline">Yoga Poses</h1>
            <p>I took my first yoga class 8 years ago and got hooked by the calm and relaxed feeling that comes after a class. As a runner, I have always struggled with flexibility, which is why I originally found yoga. At the time I had no idea how it would drastically change my life for the better. Working in a stressful corporate job, I suffered severe anxiety and panic attacks. Yoga has substantially reduced my anxiety and removed panic attacks from my life entirely.</p>
              <p>I am passionate about continuing my journey to share the knowledge passed to me from my teachers and make a difference to the lives around me.</p>
                        
            <hr style={{border: '1px solid #73a9ce'}}/>
            <p>Please contact me directly for a consultation about your needs.</p>
            <hr style={{border: '1px solid #73a9ce'}}/>
          </div>          
        </div>
      </Layout>
    )
  }
}

export default YogaPosesPageIndex

export const pageQuery = graphql`
  query YogaPosesPageIndexQuery {
    site {
      siteMetadata {
        title
      }
    }    
  }
`
